<template>
   <div class="loader">
        <div class="bars-common bar-one"></div>
        <div class="bars-common bar-two"></div>
        <div class="bars-common bar-three"></div>

        <div class="squares-common square-one"></div>
        <div class="squares-common square-two"></div>
    </div>
</template>
